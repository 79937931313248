<template>
  <div v-loading="pageLoading">
    <div class="card mb-5 mb-xl-10" v-if="oldTemplateData.name != ''">
      <div class="card-header border-0 pt-6">
        <div class="d-flex flex-column">
          <div class="d-flex align-items-center mb-2 float-left">
            <span class="text-gray-900 fs-2 fw-bolder me-1">{{ oldTemplateData.name }}</span>
          </div>
          <div class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2 float-left">
            <span class="d-flex align-items-center text-gray-400 me-5 mb-2" v-html="oldTemplateData.description.length > 500 ? oldTemplateData.description.substring(0,500)+'...' : oldTemplateData.description"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <el-form @submit.prevent="submitForm()" :model="formData" :rules="rules" ref="formRef" class="w-100">
        <div class="row col-md-12">
          <div class="col-md-9">
            <div class="row card-header border-0 pt-12">
              <div class="row">
                <div class="col-md-4">
                  <label class="required fw-bold">{{ "Template Type" }}</label>
                  <el-form-item prop="templateType">
                    <el-select size="large" placeholder="Template Type" v-model="formData.templateType" @change="changeTemplateType($event)">
                      <el-option :label="templateData.label" :value="templateData.value" :key="templateData" v-for="templateData in templateTypes"></el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div class="col-md-4">
                  <label class="fw-bold">{{ generalConstants.SELECTTRADINGPARTNER }}</label>
                  <el-form-item prop="tradingPartnerId">
                    <el-select size="large" :placeholder="generalConstants.SELECTTRADINGPARTNER" v-model="formData.tradingPartnerId" @change="getTradingPartnerContent($event)">
                      <el-option :label="tradingData.store_name" :value="tradingData.trading_partner_id" :key="tradingData" v-for="tradingData in tradingPartnerData"></el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div class="col-md-4">
                  <label class="required fw-bold">{{ templateManagementConstants.TEMPLATENAME }}</label>
                  <el-form-item prop="name">
                    <el-input size="large" type="text" v-model="formData.name" :placeholder="templateManagementConstants.TEMPLATENAME" class="mb-2" />
                  </el-form-item>
                </div>
              </div>
              <div class="row">
                <label class="required mb-1 fw-bold">{{ templateManagementConstants.TEMPLATEMANAGEMENTCONTENT }}</label>
                <div class="col-md-12">
                  <editor id="template_editor" api-key="1hu1iumt8djla2ppuivgrhpymfu1w2ey3obee45zxab7wgd2" :init="{
                  verify_html: false,
                  selector: 'textarea#file-picker',
                  setup: function (editor) {
                    editor.on('init', function (e) {
                      editor.setContent('');
                    });
                  },
                  height: 1000,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists image charmap',
                    'searchreplace visualblocks code fullscreen',
                    'print preview anchor insertdatetime media',
                    'paste code help wordcount table',
                    'insertdatetime media table paste code help wordcount',
                    'code',
                    'image'
                  ],
                  file_picker_types: 'file image media',
                  a11y_advanced_options: true,
                  image_advtab: true,
                  image_uploadtab: true,
                  images_file_types: 'jpg,svg,webp',
                  automatic_uploads: true,
                  style_formats: [
                    {title: 'Image Left', selector: 'img', styles: {
                      'float' : 'left',
                      'margin': '0 10px 0 10px'
                    }},
                    {title: 'Image Right', selector: 'img', styles: {
                      'float' : 'right',
                      'margin': '0 10px 0 10px'
                    }}
                  ],
                  toolbar:
                    'undo redo | formatselect | bold italic backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | help | code | image'
                }">
                  </editor>
                </div>
              </div>
              <div class="col-md-12 mt-5 mb-5">
                <button type="button" class=" btn btn-primary me-2" v-if="((permission.isEditAllowed) || (permission.isAddAllowed && permission.isEditAllowed))" :disabled="loading" @click="submitForm(formRef, 'save')">{{generalConstants.SAVE}}<span v-if="loading" class="spinner-border spinner-border-sm align-middle ms-2"></span>
                </button>
                <button type="button" class=" btn btn-primary me-2" :disabled="loadingback" @click="submitForm(formRef, 'save_back')">{{generalConstants.SAVEANDBACK}}<span v-if="loadingback" class="spinner-border spinner-border-sm align-middle ms-2"></span></button>
                <button type="button" class=" btn btn-secondary me-2" @click.prevent="$router.push({ name: 'template-management'})">{{generalConstants.CANCEL}}</button>
                <button v-if="formData.templateType == 1 || formData.templateType == 2 || formData.templateType == 3 || formData.templateType == 4" type="button" class=" btn btn-dark me-2" @click.prevent="setDefaultContent(formData.templateType)">{{ templateManagementConstants.DEFAULT_CONTENT }}</button>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <h3 class="mt-10">{{ templateManagementConstants.AVAILABLEVARIABLES }}</h3><hr>
              <div class="row">
                <el-input v-model="searchVariable" v-on:keyup="getTemplateVariables" size="large" type="text" placeholder="Search Variables" class="m-2"/>
                <div v-for="(item,index) in templateVariables" :key="index" :id="'variablelabel_' + index" class="ms-2 d-flex align-items-center">
                  <label @click="addVariable(index)" class="d-block me-2"><i class="bi bi-plus-circle-fill fs-4"></i></label>
                  <div class="d-flex align-items-center">
                  <label :id="'label_' + index" class="d-block">{{item.value}}</label> 
                    <button type="button" :id="'variable_'+index"
                      :data-clipboard-text="item.value" class="btn btn-active-color-primary btn-icon btn-sm btn-outline-light d-inline-block ms-2">
                    <span class="svg-icon svg-icon-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path opacity="0.5" d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z" fill="black" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z" fill="black" />
                    </svg>
                    </span>
                    </button>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import { reactive, ref, onMounted, onUpdated } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { notificationFire } from "@/composable/notification.js";
import ApiService from "@/core/services/ApiService";
import { formatText } from "@/core/helpers/common";
import globalConstant from "@/core/data/globalConstant.js";
import { useRoute, useRouter } from "vue-router";
import Editor from "@tinymce/tinymce-vue";
import { checkEmptySpace } from "@/composable/common-validation.js";
import useEventBus from "../../composable/useEventBus";
import { setCurrentPageActionButtons } from "@/core/helpers/actionButtons";
import { loggedInUserRoles, loggedInUserPermissions } from "@/composable/get-roles.js";
import { ElMessageBox } from 'element-plus';
import ClipboardJS from "clipboard";

export default {
  name: "add-edit-template",
  components: {
    editor: Editor,
  },
  setup() {
    const { onEvent, emitEvent } = useEventBus();
    const route = useRoute();
    const router = useRouter();
    const loading = ref(false);
    const loadingback = ref(false);
    const pageLoading = ref(false);
    const disabled = ref(false);
    const editorData = ref();
    const formRef = ref(null);
    const generalConstants = globalConstant.general;
    const templateManagementConstants = globalConstant.templateManagement;
    const tradingPartnerData = reactive([]);
    const searchVariable = ref('');
    const oldTemplateData = reactive({
      name: '',
      description: ''
    });
    const formData = ref({
      templateId: "",
      templateType: "",
      name: "",
      tradingPartnerId: "",
      tradingPartnerName: ""
    });
    const permission = reactive({
      isAddAllowed: false,
      isEditAllowed: false
    })
    const templateTypes = [
      {
        value: "1",
        label: "Job Ticket",
      },
      {
        value: "2",
        label: "Shipment Label",
      },
      {
        value: "3",
        label: "Package Slip",
      },
      {
        value: "4",
        label: "Invoice",
      },
    ];

    const templateVariables = [
      {
        value: "{order_id}",
        label: "Order Number",
      },
      {
        value: "{order_date}",
        label: "Order Date",
      },
      {
        value: "{order_due_date}",
        label: "Order Due Date",
      },
      {
        value: "{customer_company}",
        label: "Customer Company",
      },
      {
        value: "{customer_name}",
        label: "Customer Name",
      },
      {
        value: "{customer_email}",
        label: "Customer Email",
      },
      {
        value: "{customer_phone}",
        label: "Customer Phone",
      },
      {
        value: "{customer_address}",
        label: "Customer Address",
      },
      {
        value: "{corporate_name}",
        label: "Corporate Name",
      },
      {
        value: "{billing_address}",
        label: "Billing Address",
      },
      {
        value: "{shipping_address}",
        label: "Shipping Address",
      },
      {
        value: "{shipping_company_name}",
        label: "Shipping Company Name",
      },
      {
        value: "{shipping_method}",
        label: "Shipping Method",
      },
      {
        value: "{shipping_charges}",
        label: "Shipping Charges",
      },
      {
        value: "{product_title}",
        label: "Product Title",
      },
      {
        value: "{product_size}",
        label: "Product Size",
      },
      {
        value: "{product_quantity}",
        label: "Product Quantity",
      },
      {
        value: "{price}",
        label: "Price",
      },
      {
        value: "{order_product_id}",
        label: "Order Product Number",
      },
      {
        value: "{transaction_id}",
        label: "Transaction ID",
      },
      {
        value: "{subtotal}",
        label: "Subtotal",
      },
      {
        value: "{discount}",
        label: "Discount",
      },
      {
        value: "{tax}",
        label: "Tax",
      },
      {
        value: "{payment_processing_fees}",
        label: "Payment Processing Fees",
      },
      {
        value: "{total_amount}",
        label: "Total Amount",
      },
    ];
    //Get click event of toolbar buttons
    onEvent("actionName", (actionName) => {
      if (actionName == "save") {
        submitForm(formRef, "save", true);
      } else if (actionName == "save_back") {
        submitForm(formRef, "save_back", true);
      } else if (actionName == "cancel") {
        router.push({ name: "template-management" });
      }
    });
    //Rules for template management form
    const rules = ref({
      templateType: [
        {
          required: true,
          message: "Template type is required",
          trigger: "blur",
        },
      ],
      name: [
        {
          required: true,
          validator: checkEmptySpace,
          requiredmessage: "Template name is required",
          trigger: "blur",
        },
      ],
    });

    const changeTemplateType = (val) => {
      formData.value.templateType = val;
      formData.value.name = "";
      tinymce.activeEditor.setContent("");
      getTemplateContent();
      if (typeof route.params.id == "undefined") {
      }
    };

    const getTradingPartnerContent = (val) => {
      formData.value.name = "";
      tinymce.activeEditor.setContent("");
      formData.value.tradingPartnerId = val;
      let index = tradingPartnerData.findIndex(function(product) {
        return product.trading_partner_id == val
      });
      formData.value.tradingPartnerName = tradingPartnerData[index].store_name;
      getTemplateContent();
    };

    const getTradingPartners = async () => {
      await ApiService.query("get-active-trading-partners")
        .then(({ data }) => {
          tradingPartnerData.push({
            trading_partner_id: 0,
            store_name: "Default",
          });
          data.data.forEach((obj) => {
            tradingPartnerData.push({
              trading_partner_id: obj.trading_partner_id,
              store_name: obj.get_user_oauth[0].name,
            });
          });
          formData.value.tradingPartnerId = 0;
        })
        .catch((error) => {
          let response = error.response;
          if (response) {
            let message = response.data.message;
            notificationFire(message, "error");
          }
        });
    };

    //Fetch template management content
    const getTemplateContent = async () => {
      loading.value = true;
      pageLoading.value = true;
      let searchParams = {
        templateType: formData.value.templateType,
        templateId:
          typeof route.params.id == "undefined"
            ? formData.value.templateId
            : route.params.id,
        tradingPartnerId: formData.value.tradingPartnerId,
      };
      await ApiService.post("template-management/fetch-template-data", {
        params: searchParams,
      })
        .then((data) => {
          if (data.data.data) {
            setTimeout(() => {
              tinymce.activeEditor.setContent(
                JSON.parse(data.data.data.template_content)
              );
              formData.value.templateId = data.data.data.template_id;
              formData.value.templateType = data.data.data.template_type;
              if (data.data.data.trading_partner_id != 0) {
                formData.value.tradingPartnerId =
                  data.data.data.trading_partner_id;
              } else {
                formData.value.tradingPartnerId = 0;
              }
              formData.value.name = oldTemplateData.name = data.data.data.template_title;
              loading.value = false;
              pageLoading.value = false;
              templateTypes.map(function (element) {
                if(element.value == data.data.data.template_type)
                  oldTemplateData.description = element.label;
              });
            }, 2000);
            setCurrentPageBreadcrumbs(formatText('Edit Template'), [{name: formatText("template-management"),path: "template-management",},{ name: formatText('Edit Template'), active: true },]);
          } else {
            formData.value.templateId = '';
            setCurrentPageBreadcrumbs(formatText('Add Template'), [{name: formatText("template-management"),path: "template-management",},{ name: formatText('Add Template'), active: true },]);
            if(formData.value.templateType != ''){
              setDefaultContent(formData.value.templateType,false);
            }
            loading.value = false;
            pageLoading.value = false;
          }
        })
        .catch((error) => {
          let response = error.response;
          if (response) {
            let message = response.data.message;
            notificationFire(message, "error");
          }
          pageLoading.value = false;
          loading.value = false;
        });
    };

    //add-update template management content
    const submitForm = async (
      formEl,
      btnName,
      isToolbarButtonsSubmitAction = false
    ) => {
      formEl = isToolbarButtonsSubmitAction ? formEl.value : formEl;
      if (!formEl) {
        return;
      }
      formEl.validate((valid) => {
        if (!valid) return false;
        if (tinymce.activeEditor.getContent() === "") {
          notificationFire("Please enter template content", "warning");
          return false;
        }
        isToolbarButtonsSubmitAction
          ? emitEvent("loader", { save_loader: true, action: btnName })
          : btnName == "save"
          ? (loading.value = true)
          : (loadingback.value = true);
        let editorContent = tinymce.activeEditor.getContent();
        ApiService.post("template-management", {
          templateId: formData.value.templateId,
          templateType: formData.value.templateType,
          tradingPartnerId: formData.value.tradingPartnerId,
          templateTitle: formData.value.name,
          templateContent: JSON.stringify(editorContent),
        })
          .then((data) => {
            isToolbarButtonsSubmitAction
              ? emitEvent("loader", { save_loader: false, action: btnName })
              : btnName == "save"
              ? (loading.value = false)
              : (loadingback.value = false);
            oldTemplateData.name = formData.value.name;
            templateTypes.map(function (element) {
              if(element.value == formData.value.templateType)
                oldTemplateData.description = element.label;
            });
            if (typeof route.params.id === "undefined") {
              setCurrentPageBreadcrumbs(formatText('edit-template'), [{name: formatText("template-management"),path: "template-management",},{ name: formatText('edit-template'), active: true }]);
              router.push({
                name: "edit-template",
                params: { id: data.data.data.template_id },
              });
            }
            if (btnName == "save_back") {
              router.push({ name: "template-management" });
            }
            formData.value.templateId = data.data.data.template_id;
            notificationFire(data.data.message, "success");
          })
          .catch((error) => {
            isToolbarButtonsSubmitAction
              ? emitEvent("loader", { save_loader: false, action: btnName })
              : btnName == "save"
              ? (loading.value = false)
              : (loadingback.value = false);
            let response = error.response;
            if (response != "") {
              let message = response.data.message;
              notificationFire(message, "error");
            }
          });
      });
    };

    //Generate Current Page Toolbar Dynamic Buttons
    function generateHeaderToolbarButtons(){
      return setCurrentPageActionButtons([{"name" :generalConstants.SAVE, 'action': 'save','class': 'btn-primary me-3','isLoader': false, 'isAllowed': (permission.isEditAllowed) || (permission.isAddAllowed && permission.isEditAllowed)}, {"name": generalConstants.SAVEANDBACK , 'action': 'save_back','class': 'btn-primary me-3','isLoader':false }, {"name": generalConstants.CANCEL , 'action': 'cancel','class': 'btn-secondary','isLoader':false }]);
    }

    const setDefaultContent = (templateType,confirm=true) => {
      if(confirm == true){
        ElMessageBox.confirm(
          templateManagementConstants.ALERT_MESSAGE,
          generalConstants.ALERT_WARNING,
          {
            confirmButtonText: generalConstants.ALERT_CONFIMATION,
            cancelButtonText: generalConstants.ALERT_CANCEL,
            type: 'warning',
            center: true
          }
        )
        .then(() => {
          loading.value = true;
          ApiService.query("default-template-management-data/"+templateType)
          .then(({ data }) => {
            tinymce.activeEditor.setContent(data);
            loading.value = false;
          })
          .catch((error) => {
            let response = error.response;
            if (response) {
              let message = response.data.message;
              notificationFire(message, "error");
            }
            loading.value = false;
          });
        })
      }else{
        loading.value = true;
        ApiService.query("default-template-management-data/"+templateType)
        .then(({ data }) => {
          if(templateType == 1){
            formData.value.name = oldTemplateData.name = formData.value.tradingPartnerName != '' ? formData.value.tradingPartnerName+' - Job Ticket' : 'Default - Job Ticket';
            oldTemplateData.description = 'Job Ticket';
          }else if(templateType == 2){
            formData.value.name = oldTemplateData.name = formData.value.tradingPartnerName != '' ? formData.value.tradingPartnerName+' - Shipment Label' : 'Default - Shipment Label';
            oldTemplateData.description = 'Shipment Label';
          }else if(templateType == 3){
            formData.value.name = oldTemplateData.name = formData.value.tradingPartnerName != '' ? formData.value.tradingPartnerName+' - Packaging Slip' : 'Default - Packaging Slip';
            oldTemplateData.description = 'Packaging Slip';
          } else if(templateType == 4){
            formData.value.name = oldTemplateData.name = formData.value.tradingPartnerName != '' ? formData.value.tradingPartnerName+' - Invoice' : 'Default - Invoice';
            oldTemplateData.description = 'Invoice';
          }
          tinymce.activeEditor.setContent(data);
          loading.value = false;
        })
        .catch((error) => {
          let response = error.response;
          if (response) {
            let message = response.data.message;
            notificationFire(message, "error");
          }
          loading.value = false;
        });
      }
    }

    const addVariable = (index) => {
      let label = document.querySelector('#label_' + index);
      tinymce.get("template_editor").insertContent(label.innerText);
    }

    const getTemplateVariables = () => {
      templateVariables.map(function (element, key) {
        let label = document.querySelector('#variablelabel_' + key);
        if (element.value.includes(searchVariable.value)) {
          label.classList.remove('d-none');
        } else {
          label.classList.add('d-none');
        }
      });
    }

    onMounted(() => {
      setCurrentPageBreadcrumbs(formatText(route.name), [{name: formatText("template-management"),path: "template-management",},{ name: formatText(route.name), active: true }]);
      let role = loggedInUserRoles();
      let per = loggedInUserPermissions();
      if (per.includes("createTemplate") || role == "Super Admin")
        permission.isAddAllowed = true;
      if (per.includes("updateTemplate") || role == "Super Admin")
        permission.isEditAllowed = true;
      getTradingPartners();
      getTemplateContent();
      if(templateVariables.length > 0){
        for(var i=0; i < templateVariables.length; i++){
          const target = document.getElementById('variable_'+i);
          var clipboard = new ClipboardJS(target);
          clipboard.on('success', function (e) {
              const currentLabel = target.innerHTML;
              if (target.innerHTML === '<i class="bi bi-check fs-2x text-success"></i>') {
                  return;
              }
              target.innerHTML = '<i class="bi bi-check fs-2x text-success"></i>';
              setTimeout(function () {
                  target.innerHTML = currentLabel;
              }, 3000)
          });
        }
      }
      generateHeaderToolbarButtons();
    });
    onUpdated(async () => {
      generateHeaderToolbarButtons();
    });

    return {
      submitForm,
      loading,
      loadingback,
      pageLoading,
      disabled,
      editorData,
      formData,
      rules,
      formRef,
      templateVariables,
      tradingPartnerData,
      getTradingPartnerContent,
      changeTemplateType,
      generalConstants,
      templateManagementConstants,
      templateTypes,
      permission,
      setDefaultContent,
      oldTemplateData,
      addVariable,
      searchVariable,
      getTemplateVariables
    };
  },
};
</script>